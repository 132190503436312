import React from 'react';
import Page404 from '../common/Page404';

const Employment = () => {
  return (
    <div>
  <Page404/>
    </div>
  );
};

export default Employment;
