import Pages from "./components/pages/Pages";

function App() {
  
  return (
    <div className="App">

      <Pages />
    </div>
  );
}

export default App;
