export const languages = [
    {
      code: 'nl',
      name: 'Français',
      country_code: 'nl',
  
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
  
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ]